import { useState } from "react";
import { HiMenuAlt4 } from "react-icons/hi";
import bg from "../logo3.png";

export default function Nav() {
  const [move, setMove] = useState(false);
  function handleOpen() {
    setMove(true);
  }
  function handleClose() {
    setMove(false);
  }
  return (
    <>
      {/* for desktop */}
      <nav className="nav-container">
        <ul>
          <a href="https://ezynotify.pages.dev">
            <img src={bg} alt=" " width={110} />
          </a>
        </ul>
        <ul>
          <a href="https://ezynotify.pages.dev">
            {" "}
            <li>Home</li>
          </a>
          <a href="https://ezynotify.pages.dev/faq">
            <li>FAQ</li>
          </a>
          <a href="https://ezynotify.pages.dev/pricing">
            <li>Pricing</li>
          </a>
        </ul>
        <ul>
          <a href="https://ezynotify.pages.dev/sign-in">
            <li className="nav-login nav-button">Login</li>
          </a>
          <a href="https://ezynotify.pages.dev/sign-up">
            <li className="nav-signup nav-button">Sign up</li>
          </a>
        </ul>
      </nav>

      {/* for mobile */}
      <nav className="nav-container-mobile">
        <ul>
          {move ? (
            <li
              style={{
                marginRight: "1.5rem",
                marginLeft: "0.5rem",
                marginTop: "0.3rem",
                fontSize: "2rem",
                fontWeight: "bold",
              }}
              onClick={handleClose}
            >
              x
            </li>
          ) : (
            <li className="menu-toggle">
              <HiMenuAlt4 className="open" onClick={handleOpen} />
            </li>
          )}
        </ul>

        {/* mobile */}
        <ul className="nav-content-mobile">
          {move && <div onClick={handleClose} className="mobile-blur"></div>}
          {move && (
            <div className="mobile-nav">
              <li>
                <a href="https://ezynotify.pages.dev" className="home-btn">
                  Home
                </a>
              </li>
              <hr />
              <li>
                <a href="https://ezynotify.pages.dev/faq" className="faq-btn">
                  FAQ
                </a>
              </li>
              <hr />
              <li>
                <a
                  href="https://ezynotify.pages.dev/pricing"
                  className="pricing-btn"
                >
                  Pricing
                </a>
              </li>
              <hr />
              <li>
                <a
                  href="https://ezynotify.pages.dev/sign-in"
                  className="login-btn"
                >
                  Login
                </a>
              </li>

              <li>
                <a
                  href="https://ezynotify.pages.dev/sign-up"
                  className="register-btn"
                >
                  Sign up
                </a>
              </li>
            </div>
          )}
        </ul>

        <ul>
          <a href="https://ezynotify.pages.dev">
            <img src={bg} alt=" " width={110} className="nav-mobile-image" />
          </a>
        </ul>
        <ul>
          <a href="https://ezynotify.pages.dev/sign-in">
            <li className="nav-login nav-button">Login</li>
          </a>
        </ul>
      </nav>
    </>
  );
}
